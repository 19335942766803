export function getErrorCodeArr() {
  var codeArr = {
    1001: '接口参数有误',
    1002: '服务器有误',
    1003: '接口签名有误',
    1004: '非法的IP',
    1005: '请求频繁',
    2001: '数据插入或更新有误',
    2002: '数据删除有误',
    6001: '该用户已被封禁',
    6002: '未经授权',
    6003: '手机号未授权',
    7001: '商品不存在',
    7002: '创建订单失败',
    7003: '订单不存在',
    7004: '订单状态异常',
    7005: '支付订单失败',
    7006: '缺失参数',
    8001: '套餐订单不存在',
    8002: '预约异常',
    8003: '预约次数不足',
    8004: '当前时间段床位数已预约满',
    8005: '有姓名相同未确认完成的预约信息，请先到我的预约信息里确认旧的再预约新的',
    8006: '汇付支付订单创建失败',
    8007: '先预约最早的订单',
    8008: '疗程当天不能取消预约',
    9001: '不存在的门店',
    9002: '登录失败，联系管理员',
    9003: '操作频繁',
    9004: '验证码错误',
    9005: '上传图片数超出'
  };
  return codeArr;
}