import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import axios from 'axios';
import { getErrorCodeArr } from '@/utils/errorCode';
import router from '@/router/index';
// axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
// axios.defaults.headers['Platform'] = 'web'
// 创建axios实例
var service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  // baseURL: 'https://apit.adl.diandianhao.cn/api', // 测试环境
  // baseURL: 'https://api.adl.diandianhao.cn/api', // 生产环境
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 10 * 1000
}); // 请求拦截器

service.interceptors.request.use(function (config) {
  // 让每个请求携带自定义token 请根据实际情况自行修改
  var token = 'Bearer ' + localStorage.getItem('TOKEN');

  if (localStorage.getItem('TOKEN')) {
    config.headers['Authorization'] = token;
  }

  return config;
}, function (error) {
  console.log('err：' + error);
  return Promise.reject(error);
}); // 响应拦截器

service.interceptors.response.use(function (response) {
  var res = response.data;
  var errorCode = response.data.error.code;

  if (errorCode !== 0) {
    var errorCodeArr = getErrorCodeArr(); // 首页去掉拦截

    if (window.location.hash === '#/' || window.location.hash.indexOf('#/?') !== -1) {
      return res;
    } else {
      if (errorCode === 6002) {
        localStorage.removeItem('TOKEN');

        _Dialog.confirm({
          title: '',
          message: '新用户或登录已过期，请注册或者重新登录',
          showCancelButton: false
        }).then(function () {
          router.push({
            name: 'login'
          });
        });
      } else {
        _Toast.fail(errorCodeArr[errorCode]);
      }

      return Promise.reject(errorCodeArr[errorCode]);
    }
  } else {
    return res;
  }
}, function (error) {
  console.log('err：' + error);
  var message = error.message;

  if (message === 'Network Error') {
    message = '后端接口连接异常';
  } else if (message.includes('timeout')) {
    message = '系统接口请求超时';
  } else if (message.includes('Request failed with status code')) {
    message = '系统接口' + message.substr(message.length - 3) + '异常';
  }

  _Toast.fail(message);

  return Promise.reject(message);
});
export default service;